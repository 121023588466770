.header {
  /* margin-top: 60px; */
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #fff;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px #17d059;
}

.headerTitleSm {
  position: absolute;
  top: 18%;
  font-size: 20px;
}

.headerTitleLg {
  position: absolute;
  top: 20%;
  font-size: 5rem;
}

.headerImg{
    width: 100%;
    height: 650px;
    object-fit: cover;
}