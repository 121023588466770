.post {
  /* width: 385px;
  margin: 0px 25px 40px 25px;
  border: 3px solid black;
  border-radius: 1rem;
  padding: 1rem; */
  width: 385px;
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 4px solid #17d059;
  text-align: center;
  transition: 200ms ease-in;
  height: 300px;
}

.post:hover {
  transform: scale(1.02);
  box-shadow:  5px 5px #005800 ,   10px 10px #007e04,   15px 15px #00a633,  20px 20px #17d059;
  /* box-shadow: 1px 1px 1px #000, 3px 3px 5px blue; */
  transition: 100ms ease-in;
}

.postImg {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 7px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postCat {
  font-family: "Varela Round", sans-serif;
  font-size: 11px;
  color: #be9656;
  line-height: 20px;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.postTitle {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-top: 15px;
  cursor: pointer;
}

.postDate {
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-size: 13px;
  color: #999;
  margin-top: 15px;
}

.postDesc {
  font-family: "Varela Round", sans-serif;
  font-size: 14px;
  color: #444;
  line-height: 24px;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
