.settings {
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
}

.settingsWrapper {
  flex: 9;
  padding: 5rem;
  border-radius: 1rem;
  border: 4px solid #17d059;
  margin: 2rem;
}

.settingsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingsUpdateTitle {
  font-size: 30px;
  margin-bottom: 20px;
  color: lightcoral;
}

.settingsDeleteTitle {
  color: white;
  padding: 1rem;
  border-radius: 1rem;
  font-size: 12px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  background-color: red;
}

.settingsForm {
  display: flex;
  flex-direction: column;
}

.settingsPP {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.settingsPP > img {
  width: 70px;
  height: 70px;
  border-radius: 20px;
  object-fit: cover;
}

.settingsPPIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: lightcoral;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.settingsForm > label {
  font-size: 20px;
  margin-top: 20px;
}

.settingsForm > input {
  color: gray;
  margin: 10px 0;
  height: 30px;
  border: none;
  border-bottom: 1px solid lightgray;
}

.settingsSubmit{
    width: 150px;
    align-self: center;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: teal;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 1.25rem;
}