.login {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://user-images.githubusercontent.com/60937304/164972670-51318f0f-b7ac-4726-84c4-89e762153cc7.jpg");
  background-size: 100%;
}

.loginTitle {
  font-size: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  color: white;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px #17d059;
}

.loginForm {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
}

.loginForm > label {
  margin: 10px 0;
  font-family: 'Poppins', sans-serif;
  color: white;
  font-size: 1.25rem;
}

.loginInput {
  padding: 10px;
  background-color: white;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
}

.loginButton {
  margin-top: 20px;
  cursor: pointer;
  background-color: #17d059;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 10px;
  font-size: 1.25rem;
  font-family: 'Poppins', sans-serif;
}

.loginButton:disabled{
  cursor: not-allowed;
  background-color: rgb(252, 173, 173);
}

.loginRegisterButton {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: teal;
  cursor: pointer;
  border: none;
  padding: 10px;
  color: white;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
}
